<template>
  <button
    class="mpz-button"
    :class="`mpz-button--${view} mpz-button--${green ? 'green' : '' }`"
    @click="onClick"
    :disabled="disabled"
  >
    <slot name="iconLeft" />
    {{ label }}
    <slot name="iconRight" />
  </button>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  view: {
    type: String,
    default: 'primary',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  green: {
    type: Boolean,
    default: false,
  }
})
const emit = defineEmits(['clicked'])

const onClick = () => {
  if (!props.disabled){
    emit('clicked')
  }
}
</script>

<style scoped lang="scss">
.mpz-button{
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  padding: 10px 20px;
  border: 1px solid #161616;
  border-radius: 100px;
  background: transparent;
  width: fit-content;
  white-space: nowrap;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;

  &:disabled{
    opacity: .5;
  }
  &:not([disabled]):hover {
    background: #DADADA;
    border: 1px solid #DADADA;
  }
  &--green{
    &:not([disabled]):hover {
      background: #CBF8CD;
      border: 1px solid #CBF8CD;
    }
  }
}
</style>
