import { createApp } from 'vue'
import App from './App.vue'
// import VueCustomDirectives from "vue3-directives";
import router from './router'
import store from './store'

import MPZButton from "@/components/UI/MPZButton.vue"
import MPZInput from "@/components/UI/MPZInput.vue"
import MPZModal from "@/components/UI/MPZModal.vue"


createApp(App)
  .use(store)
  // .use(VueCustomDirectives)
  .use(router)

  .component('MPZButton', MPZButton)
  .component('MPZInput', MPZInput)
  .component('MPZModal', MPZModal)

  .mount('#app')
