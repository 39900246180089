import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const Home = () => import('../pages/main.vue')
const AboutCompany = () => import('../pages/aboutcompany.vue')
const Contacts = () => import('../pages/contacts.vue')
const Documents = () => import('../pages/documents.vue')
const serviceID = () => import('../pages/serviceID.vue')
const Account = () => import('../pages/account.vue')


const NotFound = () => import('../pages/not-found.vue')


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      routeTitle: 'Главная',
    },
  },
  {
    path: '/aboutcompany',
    name: 'AboutCompany',
    component: AboutCompany,
    meta: {
      routeTitle: 'О компании',
    },
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
    meta: {
      routeTitle: 'Контакты',
    },
  },
  {
    path: '/documents',
    name: 'Documents',
    component: Documents,
    meta: {
      routeTitle: 'Документы',
    },
  },
  {
    path: '/services',
    redirect: to => {
      return { path: '/' }
    }
  },
  {
    path: '/services/:id',
    name: 'serviceID',
    component: serviceID,
    meta: {
      routeTitle: 'Сервис',
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      routeTitle: 'Личный кабинет',
    },
  },
  { path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      routeTitle: '404',
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
