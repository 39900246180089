<template>
  <header class="mpz-header container">

    <div class="mpz-header__nav">
      <router-link class="nav-link" to="/aboutcompany">О компании</router-link>
      <router-link class="nav-link" to="/documents">Документы</router-link>
      <router-link class="nav-link" to="/contacts">Контакты</router-link>
    </div>

    <router-link to="/" class="mpz-header__logo">
      <img
        src="@/assets/images/logo.svg"
        alt="Логотип"
      >
    </router-link>

    <div class="account-btns">
      <MPZButton
        :label="$store.state.user.token ? $store.state.user.company : 'Личный кабинет'"
        @clicked="$store.state.user.token ? $router.push('/account') : isShowMPZModalLogin = true"
      />
      <!-- @clicked="$router.push('/account')" -->

      <div
        class="logout"
        @click="isShowMPZModalExit = true"
        v-if="$store.state.user.token"
      >
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="38" height="38" rx="19" fill="#161616" fill-opacity="0.5"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M21 8.55664V29.2804L11 26.7804V12.3066L21 8.55664ZM13 13.6926V25.2189L19 26.7189V11.4426L13 13.6926Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M25 12.5H22.5V10.5H27V27.5H22.5V25.5H25V12.5Z" fill="white"/>
          <circle cx="17" cy="19" r="1" fill="white"/>
        </svg>
      </div>
    </div>

    <div class="adaptive-block">
      <div class="adapt-menu" ref="target">
        <img
          v-if="isAdaptiveMenu"
          class="icon"
          @click="isAdaptiveMenu = !isAdaptiveMenu"
          src="@/assets/images/adapt-menu-close.svg"
        >
        <img
          v-else
          class="icon"
          @click="isAdaptiveMenu = !isAdaptiveMenu"
          src="@/assets/images/adapt-menu.svg"
        >

        <div @click="isAdaptiveMenu = false" :class="{show: isAdaptiveMenu}" class="adapt-menu-background"/>

        <div :class="{show: isAdaptiveMenu}" class="adapt-menu-block">
          <div class="adapt-menu-block__links">
            <router-link to="/aboutcompany">О компании</router-link>
            <router-link to="/documents">Документы</router-link>
            <router-link to="/contacts">Контакты</router-link>
          </div>

          <div class="account-btns-adapt">
            <MPZButton
              :label="$store.state.user.token ? $store.state.user.company : 'Личный кабинет'"
              @clicked="$store.state.user.token ? $router.push('/account') : isShowMPZModalLogin = true"
            />
            <div
              class="logout"
              @click="isShowMPZModalExit = true"
              v-if="$store.state.user.token"
            >
              <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="38" height="38" rx="19" fill="#161616" fill-opacity="0.5"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21 8.55664V29.2804L11 26.7804V12.3066L21 8.55664ZM13 13.6926V25.2189L19 26.7189V11.4426L13 13.6926Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M25 12.5H22.5V10.5H27V27.5H22.5V25.5H25V12.5Z" fill="white"/>
                <circle cx="17" cy="19" r="1" fill="white"/>
              </svg>
            </div>
          </div>

        </div>
      </div>
    </div>

    <transition name="default">
      <MPZModalExit
        v-if="isShowMPZModalExit"
        @close="isShowMPZModalExit = false"
      />
    </transition>

    <transition name="default">
      <MPZModalLogin
        v-if="isShowMPZModalLogin"
        @close="isShowMPZModalLogin = false"
        @successSended="openSmsModal"
      />
    </transition>

    <transition name="default">
      <MPZModalSms
        v-if="isShowMPZModalSms"
        @close="isShowMPZModalSms = false"
        :phone="temporaryPhone"
      />
    </transition>

  </header>
</template>

<script setup>
import { ref, watch, watchEffect } from 'vue'
import { useRoute } from 'vue-router'
import MPZModalExit from '@/components/modals/MPZModalExit.vue'
import MPZModalLogin from '@/components/modals/MPZModalLogin.vue'
import MPZModalSms from '@/components/modals/MPZModalSms.vue'


const route = useRoute()

const target = ref(null)
const isAdaptiveMenu = ref(false)

const isShowMPZModalExit = ref(false)
const isShowMPZModalLogin = ref(false)
const isShowMPZModalSms = ref(false)

watch(() => route.path, () => isAdaptiveMenu.value = false)

const openAcc = () => {
  isAdaptiveMenu.value = false
  isShowModalAccountProcess.value = true
}

const position = ref(0)

watchEffect(()=> {
  if(isAdaptiveMenu.value){
    position.value = window.scrollY
    document.body.style.top = `-${window.scrollY}px`
    document.body.style.position = 'fixed'
  } else {
    document.body.style.position = ''
    document.body.style.top = ''
    window.scrollTo({top: position.value})
  }
})

const temporaryPhone = ref('')

const openSmsModal = (e) => {
  temporaryPhone.value = e
  isShowMPZModalLogin.value = false
  isShowMPZModalSms.value = true
}
</script>

<style lang="scss" scoped>
  .mpz-header{
    z-index: 5;
    padding: 30px 15px 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    @media (max-width: 1100px){
      border-bottom: 1px solid #E6E6E6;
      padding: 8px 15px;
    }
    &__nav{
      @media (max-width: 1100px){
        display: none;
      }
      .nav-link{
        margin-right: 24px;
        font-size: 16px;
        line-height: 19px;
        text-decoration: none;
        position: relative;
        transition: ease .5s;
        border-bottom: 1px solid transparent;
        white-space: nowrap;
        &:hover{
          border-bottom: 1px solid #212121;
        }
        &.router-link-active,
        &.router-link-exact-active{
          border-bottom: 1px solid #212121 !important;
        }
        &:last-child{
          margin-right: 0;
        }
      }
    }
    &__logo{
      position: absolute;
      left: 50%;
      top: 38px;
      transform: translateX(-50%);
      @media (max-width: 1100px){
        position: relative;
        left: auto;
        top: auto;
        transform: none;
        margin-top: 9px;
      }
    }
  }

.account-btns{
  display: flex;
  align-items: center;

  @media (max-width: 1100px){
    display: none;
  }

  .logout{
    width: 38px;
    height: 38px;
    margin-left: 10px;
    cursor: pointer;

    &:hover{
      svg > rect {
        fill-opacity: 1;
      }
    }

    svg *{
      transition: ease .3s
    }
  }
}

.account-btns-adapt{
  display: flex;
  align-items: center;

  .logout{
    margin-left: 10px;
    width: 38px;
    height: 38px;
    cursor: pointer;
  }
}

.adaptive-block{
  padding: 12px 14px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  display: none;

  &__user-btn{
    display: flex;
    align-items: center;
    display: none;
    cursor: pointer;
    @media (max-width: 740px){
      display: flex;
    }
  }
  @media (max-width: 1100px){
    display: flex;
  }
}

  .adapt-menu{
    position: relative;
    width: 24px;
    height: 24px;

    .icon{
      cursor: pointer;
    }
    &-background{
      position: fixed;
      width: 100vw;
      position: fixed;
      right: 0;
      top: 65px;
      width: 100vw;
      height: calc(100vh - 65px);
      background-color: rgba(black, .7);
      opacity: 0;
      visibility: hidden;
      transition: ease .3s;
      &.show{
        visibility: visible;
        opacity: 1;
      }
    }
    &-block{
      position: fixed;
      right: 0;
      top: 45px;
      width: 100vw;
      background: white;
      border-radius: 0 0 30px 30px;
      z-index: 3;
      padding: 25px;
      visibility: hidden;
      opacity: 0;
      transition: ease .3s;
      &__header{
        display: flex;
        align-items: center;
        justify-content: center;
        a{
          text-decoration: none;
          font-size: 16px;
        }
      }
      &__links{
        overflow: auto;
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 180px);
        margin-bottom: 30px;
        a{
          margin-bottom: 30px;
          text-decoration: none;
          font-size: 16px;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      &.show{
        visibility: visible;
        opacity: 1;
        transform: translateY(20px)
      }
    }
  }

  .icon{
    width: 24px;
    height: 24px;
    display: block;
  }

</style>