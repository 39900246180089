<template>
  <MPZModal @close="$emit('close')">
    <div class="content">
      <svg @click="$emit('close')" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 1L8 8M8 8L1 15M8 8L1 1M8 8L15 15" stroke="#A1A1A1" stroke-width="2"/>
      </svg>

      <h3 class="title-3">Вход в личный кабинет</h3>

      <MPZInput
        v-model="phone"
        placeholder="Номер телефона"
        masked="tel"
        :error="error"
      />
      <div class="call-btn">
        <MPZButton
          label="Далее"
          @clicked="sendForm"
        />
      </div>
    </div>
  </MPZModal>
</template>

<script setup>
import { defineEmits, ref } from 'vue'
import MPZModal from '@/components/UI/MPZModal.vue'
import useApi from '@/services/useApi'
import formattedPhone from '@/helpers/formattedPhone.js'

const emit = defineEmits(['close', 'successSended'])

const phone = ref('')

const error = ref('')

const sendForm = async() => {
  error.value = ''

  const req = await useApi('post', 'users/auth/by_phone/send_sms', {
    phone: formattedPhone(phone.value)
    // phone: '+79609509125'
  })
  if(req.error){
    error.value = req.error
  } else {
    error.value = ''
    emit('successSended', formattedPhone(phone.value))
  }
}
</script>

<style scoped lang="scss">
.content{
  padding: 20px 12px 0px 12px;
  position: relative;

  svg{
    position: absolute;
    right: 5px;
    top: -5px;
    padding: 5px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
}
.call-btn{
  width: fit-content;
  margin-top: 30px;
}
h3{
  margin-bottom: 20px;
}
p{
  opacity: 0.5;
  margin-bottom: 20px;
}
</style>
