<template>
  <div
    class="mpz-input"
  >
    <input
      :disabled="disabled"
      :placeholder="placeholder"
      :type="type"
      :value="modelValue"
      :maxlength="maxLength"
      @input="onInput"
      autocomplete="off"
      ref="target"
      :class="{error}"
      v-maska="isMasked"
    />

    <div class="mpz-input__error-message">{{error}}</div>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, computed, onMounted } from 'vue'
import { maska as vMaska } from 'maska'

const props = defineProps({
  modelValue: {
    // type: String,
    default: null,
  },
  maxLength: {
    type: String,
  },
  placeholder: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'text',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  error: {
    type: String,
    default: '',
  },
  masked: {
    type: String,
    default: '',
  }
})
const emit = defineEmits(['update:modelValue', 'onInput'])

const target = ref(null)

const isMasked = computed(()=>{
  if(props.masked === 'tel') return '+7(###) ### ## ##'
})

const onInput = (e) => {
  emit('onInput')
  emit('update:modelValue', e.target.value)
}
</script>


<style scoped lang="scss">
.mpz-input {
  position: relative;
  width: 100%;
  input {
    padding: 10px 0;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: 0.01em;
    border: none;
    border-bottom: 1px solid #A2A2A2;
    background: transparent;
    transition: ease .3s;
    color: #212121;
    width: 100%;

    &:disabled {
      opacity: .5;
    }
    &::placeholder {
      color: #212121;
      opacity: .5;
    }
    &.error{
      border-bottom: 1px solid #E37E7E;
    }
    &:focus{
      outline: none;
    }
  }
  &__error-message{
    position: absolute;
    bottom: -20px;
    left: 0;
    color: #E37E7E;
    font-size: 12px;
    line-height: 130%;
  }
}

// hide arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
