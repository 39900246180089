import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
    user: {
      token: localStorage.getItem('mpz-token') || null,
      company: localStorage.getItem('mpz-company') || null,
      phone: localStorage.getItem('mpz-phone') || null
    },
    strapiData: {
      mainPageData: {
        title: '',
        subtitle: '',
        description_title: '',
        description_text: '',
      },
      commonData: {
        partnersMapUrl: '',
        nameOfCompany: '',
        email: '',
        tel1: '',
        tel2: '',
        policy: '',
        agreement: '',
      },
      documents: [],
      workers: [],
      about: {
        title: '',
        banner: '',
        dn1: '',
        dt1: '',
        dn2: '',
        dt2: '',
      },
      services: []
    },
  },
  mutations: {
    setUsersData(state, data) {
      state.user.token = data.token
      state.user.company = data.company_name
      state.user.phone = data.phone
      localStorage.setItem('mpz-token', data.token)
      localStorage.setItem('mpz-company', data.company_name)
      localStorage.setItem('mpz-phone', data.phone)

    },
    logoutUser(state) {
      state.user.token = null
      state.user.company = null
      state.user.phone = null
      localStorage.removeItem('mpz-token')
      localStorage.removeItem('mpz-company')
      localStorage.removeItem('mpz-phone')
    },
    async getStrapiData(state){
      const main = await axios.get(`${process.env.VUE_APP_STRAPI_API}/api/glavnayas`)
      state.strapiData.mainPageData.title = main.data.data[0].attributes.title || ''
      state.strapiData.mainPageData.subtitle = main.data.data[0].attributes.subtitle || ''
      state.strapiData.mainPageData.description_title = main.data.data[0].attributes.description_title || ''
      state.strapiData.mainPageData.description_text = main.data.data[0].attributes.description_text || ''


      const common = await axios.get(`${process.env.VUE_APP_STRAPI_API}/api/obshhees?populate=carta_partnera,policy,agreement`)
      state.strapiData.commonData.nameOfCompany = common.data.data[0].attributes.company_name
      state.strapiData.commonData.partnersMapUrl = `${process.env.VUE_APP_STRAPI_API}${common.data.data[0].attributes.carta_partnera.data.attributes.url}`
      state.strapiData.commonData.policy = `${process.env.VUE_APP_STRAPI_API}${common.data.data[0].attributes.policy.data.attributes.url}`
      state.strapiData.commonData.agreement  = `${process.env.VUE_APP_STRAPI_API}${common.data.data[0].attributes.agreement .data.attributes.url}`
      state.strapiData.commonData.tel1  = common.data.data[0].attributes.phone_1
      state.strapiData.commonData.tel2  = common.data.data[0].attributes.phone_2
      state.strapiData.commonData.email = common.data.data[0].attributes.email


      const docs = await axios.get(`${process.env.VUE_APP_STRAPI_API}/api/dokumenties?populate=file`)
      state.strapiData.documents = docs.data.data.map((doc: any) => {
        return {
          title: doc.attributes.title,
          file: `${process.env.VUE_APP_STRAPI_API}${doc.attributes.file.data.attributes.url}`
        }
      })

      const workers = await axios.get(`${process.env.VUE_APP_STRAPI_API}/api/speczialisties`)
      state.strapiData.workers = workers.data.data


      const about = await axios.get(`${process.env.VUE_APP_STRAPI_API}/api/o-kompaniis?populate=banner`)
      state.strapiData.about.title = about.data.data[0].attributes.title
      state.strapiData.about.banner = `${process.env.VUE_APP_STRAPI_API}${about.data.data[0].attributes.banner.data.attributes.url}`
      state.strapiData.about.dn1 = about.data.data[0].attributes.description_title_1
      state.strapiData.about.dt1 = about.data.data[0].attributes.description_text_1
      state.strapiData.about.dn2 = about.data.data[0].attributes.description_title_2
      state.strapiData.about.dt2 = about.data.data[0].attributes.description_text_2


      const services = await axios.get(`${process.env.VUE_APP_STRAPI_API}/api/uslugis?populate=pod_servisies,preview,pod_servisies.preview`)

      console.log(services)
      state.strapiData.services = services.data.data.map((item: any) => {
        return {
          id: item.id,
          title: item.attributes.title,
          preview: `${process.env.VUE_APP_STRAPI_API}${item.attributes.preview.data.attributes.url}`,
          link: item.attributes.link,
          content: item.attributes.content,
          subservices: item.attributes.pod_servisies.data.map((s: any) => {
            return {
              id: s.id,
              title: s.attributes.title,
              description: s.attributes.description,
              preview: {
                url: `${process.env.VUE_APP_STRAPI_API}${s.attributes.preview.data.attributes.url}`,
                type: s.attributes.preview.data.attributes.mime.includes('video') ? 'video' : 'image'
              },
            }
          })
        }
      })
    }
  },
  actions: {
    login({commit}, data) {
      commit('setUsersData', data)
    },
    logout({commit}){
      commit('logoutUser')
    },
    getStrapiData({commit}){
      commit('getStrapiData')
    }
  },
  getters: {}
})
