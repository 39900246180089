<template>
  <footer class="mpz-footer container">
    <div class="mpz-footer__left">
      <div class="left">
        <a class="footer-item" :href="`tel:${$store.state.strapiData.commonData.tel1}`">{{ $store.state.strapiData.commonData.tel1 }}</a>
        <a class="footer-item" :href="`tel:${$store.state.strapiData.commonData.tel2}`">{{ $store.state.strapiData.commonData.tel2 }}</a>
        <a class="footer-item" :href="`mailto:${$store.state.strapiData.commonData.email}`">{{ $store.state.strapiData.commonData.email }}</a>
        <span class="footer-item text">Ишимбайский район, Урман-Бишкадарское сельское поселение, 5 км автодороги Ишимбай-Красноусольск</span>
      </div>
      <div class="right">
        <router-link to="/documents" class="footer-item">Документы</router-link>
        <a :href="$store.state.strapiData.commonData.agreement" target="_blank" class="footer-item" download>Пользовательское соглашение</a>
        <a :href="$store.state.strapiData.commonData.policy" target="_blank" class="footer-item" download>Политика обработки персональных данных</a>
      </div>
    </div>
    <div class="mpz-footer__right">
      <span class="footer-item text">Дизайн и разработка</span>
      <a href="https://qodex.tech" target="_blank">
        <img src="@/assets/images/logo-qodex.svg"/>
      </a>
    </div>
  </footer>
</template>

<script setup></script>

<style lang="scss" scoped>
  .mpz-footer{
    padding: 30px 15px;
    margin-top: 100px;
    display: flex;
    @media (max-width: 600px){
      flex-direction: column;
    }
    &__left{
      width: 66.666%;
      display: flex;
      @media (max-width: 1000px){
        width: 50%;
      }
      @media (max-width: 600px){
        width: 100%;
      }
      .left,
      .right{
        width: 50%;
        display: flex;
        flex-direction: column;
        @media (max-width: 1000px){
          width: 100%;
        }
      }
      @media (max-width: 1000px){
        flex-direction: column;
      }
    }
    &__right{
      width: 33.333%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @media (max-width: 1000px){
        width: 50%;
      }
      @media (max-width: 600px){
        align-items: flex-start;
      }
    }
  }
  .footer-item{
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: rgba(22, 22, 22, 0.5);
    margin-bottom: 15px;
    text-decoration: none;
    transition: ease .5s;
    &:hover{
      color: rgba(22, 22, 22, 1);
    }
    &.text{
      &:hover{
        color: rgba(22, 22, 22, .5);
      }
    }
    @media (max-width: 1000px){
      margin-bottom: 20px;
    }
    +a{
      img{
        @media (max-width: 1000px){
          margin-top: -30px;
        }
      }
    }
  }
</style>