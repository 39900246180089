<template>
  <div class="mpz-cookie-warn">
    <span>Продолжая работу с сайтом, Вы соглашаетесь с использованием файлов

      <a
        download
        target="_blank"
        href="/docs/Пользовательское соглашение.pdf"
        @click.prevent="download('/docs/Пользовательское соглашение.pdf')"
      >
        cookie
      </a>
    </span>
    <span class="button" @click="setPolicyCookie">Понятно</span>
  </div>
</template>

<script setup>
import { defineEmits } from 'vue'
const emit = defineEmits(['closeCookie'])

const download = file => window.open(file, '_target');

const setPolicyCookie = () => {
  localStorage.setItem('pmz-user-policy-cookie', true)
  emit('closeCookie')
}

const changeTextareaHeight = () => {
  textarea.value.style.height = (textarea.value.scrollHeight > textarea.value.clientHeight) ? (textarea.value.scrollHeight)+"px" : "45.5px";
}

</script>

<style lang="scss" scoped>
  .mpz-cookie-warn{
    background: #363636;
    z-index: 2;
    backdrop-filter: blur(5px);
    width: 100vw;
    padding: 11px 15px;
    position: fixed;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;

    span, button, a{
      font-size: 12px;
      line-height: 14px;
      align-items: center;
      letter-spacing: 0.01em;
      color: #FFFFFF;
    }
    .button{
      margin-left: 20px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
</style>