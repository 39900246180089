<template>
  <transition name="default">
    <div
      class="mpz-modal"
      @mousedown="$emit('close')"
    >
      <div @mousedown.stop class="mpz-modal__window" >

        <div class="mpz-modal__content">
          <h3 v-if="title.length" class="ec-modal__title">{{ title }}</h3>
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, onUnmounted, ref } from 'vue'

const props = defineProps({
  title: {
    type: String,
    default: '',
  }
})
const emit = defineEmits(['close'])

const position = ref(0)

onMounted(()=> {
  document.addEventListener('keyup', function (e) {
    if(e.keyCode === 27){
      emit('close')
    }
  })

  position.value = window.scrollY
  document.body.style.top = `-${window.scrollY}px`
  document.body.style.position = 'fixed'
})

onUnmounted(()=> {
  document.body.style.position = ''
  document.body.style.top = ''
  window.scrollTo({top: position.value})
})
</script>

<style scoped lang="scss">
  .mpz-modal{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(22,22,22,.7);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    &__window{
      background: white;
      border-radius: 24px;
      position: relative;
      max-height: calc(100vh - 60px);
      width: 343px;
      padding: 30px 20px;
      overflow: hidden;
    }

    &__title{
      font-size: 22px;
      line-height: 26.36px;
      max-width: 310px;
      text-align: center;
      margin: 0 auto 20px auto;
      text-align: center;
      font-weight: normal;
    }

    &__content{
      width: 100%;
      max-height: calc(100vh - 100px);
      overflow-y: auto;
      position: relative;
    }
  }
</style>
