<template>
  <MPZModal @close="$emit('close')">
    <h4 class="title-4">Уверены, что хотите выйти?</h4>

    <div class="row">
      <div class="exit-btn">
        <MPZButton
          label="Нет"
          @clicked="$emit('close')"
        />
      </div>
      <div class="exit-btn">
        <MPZButton
          label="Да"
          @clicked="logout"
        />
      </div>
    </div>
  </MPZModal>
</template>

<script setup>
import { defineEmits, ref } from 'vue'
import MPZModal from '@/components/UI/MPZModal.vue'
import store from '@/store'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()


const emit = defineEmits(['close'])

const logout = () => {
  store.dispatch('logout')
  emit('close')

  if(route.path === '/account'){
    router.push('/')
  }
}
</script>

<style scoped lang="scss">
  .title-4{
    padding: 0 10px;
  }
  .row{
    margin-top: 30px;
    padding: 0 10px;
    display: flex;
    gap: 0 30px;
    .exit-btn{
      width: 50%;

      :deep(.mpz-button) {
        width: 100%;
      }
    }
  }
</style>
