<template>
  <MPZHeader/>

  <router-view v-slot="{ Component }">
    <transition name="quick-hide">
      <component :is="Component" />
    </transition>
  </router-view>

  <MPZFooter/>

  <transition name="default">
    <MPZCookieWarn
      v-if="isShowCookiePopap"
      @closeCookie="isShowCookiePopap = false"
    />
  </transition>

</template>

<script setup>
import MPZHeader from '@/components/common/MPZHeader.vue'
import MPZFooter from '@/components/common/MPZFooter.vue'
import MPZCookieWarn from '@/components/common/MPZCookieWarn.vue'

import store from '@/store'

import {useRoute} from 'vue-router'
import {watch, ref, onMounted} from 'vue'

const isShowCookiePopap = ref(false)

onMounted(()=> {
  if(localStorage.getItem('pmz-user-policy-cookie') === null){
    isShowCookiePopap.value = true
  }

  store.dispatch('getStrapiData')
})

const route = useRoute()
watch(
  () => route.meta.routeTitle,
  () => document.title = `${route.meta.routeTitle} | ЭКО-СИТИ`
)

</script>

<style lang="scss">
body{
  margin: 0;
}
</style>
