<template>
  <MPZModal @close="$emit('close')">
    <div class="content">
      <svg @click="$emit('close')" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 1L8 8M8 8L1 15M8 8L1 1M8 8L15 15" stroke="#A1A1A1" stroke-width="2"/>
      </svg>

      <h3 class="title-3">Введите код из SMS</h3>

      <div class="description"> Код отправлен на номер <br> {{ phone }}</div>

      <div class="code-block">
        <input
          v-model="code"
          type="text"
          ref="input"
          v-maska="'####'"
          @input="oninput"
        />

        <div class="row">
          <div :class="{black: code[0], errorr: error}"></div>
          <div :class="{black: code[1], errorr: error }"></div>
          <div :class="{black: code[2], errorr: error }"></div>
          <div :class="{black: code[3], errorr: error}"></div>
        </div>
      </div>

      <transition name="bottom">
        <div v-if="error" class="error">{{error}}</div>
      </transition>

      <div class="row-sms">
        <div
          class="send-code-again"
          :class="{disabled: min > 1}"
          @click="sendCodeAgain"
        >
          Отправить код повторно
      </div>
        <div v-if="min > 1" class="timer"> {{ min }}:{{timer > 9 ? '' : '0'}}{{timer}}</div>
      </div>

    </div>
  </MPZModal>
</template>

<script setup>
import { ref, computed, onMounted, watchEffect,  defineEmits, defineProps, watch } from 'vue'
import { maska as vMaska } from 'maska'
import useApi from '@/services/useApi'
import store from '@/store'
import { useRouter } from 'vue-router'

const router = useRouter()

const props = defineProps({
  phone: {
    type: String,
    default: () => 0,
  },
})
const emit = defineEmits(['close'])

const input = ref(null)
const code = ref('')
const timer = ref(59)
const error = ref('')

const min = ref(4)

const setTime = () => {
  timer.value === 59
  setInterval(()=> timer.value--, 1000)
}

onMounted(()=> {
  input.value.focus()
  setTime()
})

watch(
  () => timer.value,
  () => {
    if(timer.value === 0){
      min.value = min.value - 1
      timer.value = 59
    }
  }
)

const oninput = () => {
  if(code.value.length === 4){
    sendForm()
    return
  }
}

const validate = computed(() => code.value.length === 4 )

const sendForm = async() => {

  error.value = ''

  const req = await useApi('post', 'users/auth/by_phone/check_sms', {
    code: code.value,
    phone: props.phone
  })

  if(req.error){
    error.value = req.error
  } else {
    store.dispatch('login', {...req, phone: props.phone})
    emit('close')
    router.push('/account')
  }
}

const sendCodeAgain = async() => {
  if(timer.value > 0) return

  timer.value = 60
  code.value = ''
  error.value = ''

  const req = await useApi('post', 'users/auth/by_phone/send_sms', {
    phone: props.phone
  })
}
</script>

<style scoped lang="scss">

.content{
  padding: 50px 30px 30px 30px;
  position: relative;

  svg{
    position: absolute;
    right: 27px;
    top: 26px;
    padding: 5px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
}

.title-3{
  margin-bottom: 10px;
}

.description{
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 130%;
  opacity: 0.5;
}

:deep(.mpz-modal__window) {
  padding: 0;
}

.code-block{
  margin: 0 auto 30px auto;
  position: relative;
  height: 50px;
  input{
    display: block;
    letter-spacing: 53px;
    padding-left: 20px;
    position: absolute;
    width: 309px;
    font-size: 32px;
    transform: translateX(4px);
    border: none;
  }
  .row{
    display: flex;
    gap: 0 10px;
    position: absolute;
    left: 0px;
    bottom: 0px;

    div{
      width: 62.5px;
      height: 1px;
      border-radius: 1px;
      background: #A1A1A1;
      transition: .3s ease;
      &.black{
        background: #212121;
      }
      &.errorr{
        background: #FF7A7A;
      }
    }
  }
}

.row-sms{
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  align-items: center;
}

.send-code-again{
  font-size: 16px;
  line-height: 19px;
  position: relative;
  cursor: pointer;
  &:before{
    position: absolute;
    left: 0;
    right: 0;
    bottom: -3px;
    content: '';
    height: 1px;
    background: #212121;
  }
  &.disabled{
    opacity: 0.5;
    cursor: auto;
  }
}

.timer{
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: rgba(22, 22, 22, 0.5);
}


.error{
  margin: 0 auto;
  margin-bottom: 20px;
  color: #FF7A7A;
  text-align: center;
}

.policy{
  text-align: center;
  margin-top: 0px;
  display: block;
  line-height: 100%;
  margin-bottom: 20px;
  a{
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
  }
  span{
    font-size: 16px;
    line-height: 20px;
    opacity: .5;
  }
}
</style>
